.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


a{

  color: white !important;

}


a:hover{
  color: rgb(13,202,240) !important;
}

.width-cards{
width: 20%;
}

.width-cards-countries{
  width: 70%;
  }


.container-cards{

  display: flex ;
  justify-content: space-evenly;
 

}


.active{
  font-size: 2rem;
}


.card-countries{
  margin: 1rem !important;

  display: flex;
  justify-content: space-evenly;
}

.container-cards-countries{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}

.center{
 
  margin: auto;
}

h3{
  text-align: center !important;
}


/*  */



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.inputBuscar{
  width: 50% !important;
}

table{
  min-width: 500px;
}

.containerInput{
  align-items: end;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 25px;
  max-width: 95%;
}

@media (max-width: 988px){
  
  .inputBuscar{
    width: 100% !important;
  }

  .App{
    padding: 40px 5px;
  }

  
.containerInput{
  padding-bottom: 15px;
}

}

.inputBuscar{
  width: 25% !important;
}

.swal2-styled.swal2-confirm{
  background-color: green !important;
}

a{
  color: #3e434d !important;
  text-decoration: none !important;

}



.btn-logout{
  
  font-size: 16px !important;
}