body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container-of-search{
  max-width: 95%;
}

.container-search{
 
  width: 40%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
 float: right; 
}

.display-none{
  display: none;
}


.card-body{
  display: flex;
  flex-direction: column;
}

.logo-fujitsu{
  width: 16%;
}

.titulo{
  font-size: 3rem;
  
}

.width-toolname{
  color: rgb(100, 95, 95);
  background-color: #fff;
  text-decoration: none;
width: 320px;
  border: none;
font-size: 2rem;
}


.width-toolname:hover{
 color: rgb(26, 25, 25) !important;
 font-size: 1.52rem;
}

.border-header{
  
  background-color: rgba(255, 255, 255, 0.26);
  border-bottom: 20px solid rgba(196, 15, 21, 0.795);
}

.border-header a, .border-header button{
  font-size: 1.5rem;
}

.border-header a:hover{
  color: rgb(26, 25, 25) !important;
  font-size: 1.52rem;
}

.center{
  margin: auto;
}